"use client"

import { useState, ReactNode, useEffect } from "react";
import { usePathname } from "next/navigation";
import { QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query"

// helpers
import { queryClient as reactQueryClient } from "@/api/query/reactQuery";
import { useSessionHistory } from "@/hooks/useSessionHistory";


export default function QueryClientProvider(
  { children, deviceViewport }: { children: ReactNode; deviceViewport: string | null; }
) {
  const pathname = usePathname();
  const [_, setSessionHistory] = useSessionHistory();

  const [queryClient] = useState(() => reactQueryClient);
  queryClient.setQueryData(["deviceViewport"], deviceViewport);

  // effects
  useEffect(() => {
    setSessionHistory(pathname);
  }, [pathname]);

  // renders
  return (
    <ReactQueryClientProvider client={queryClient}>
      {children}
    </ReactQueryClientProvider>
  );
}
