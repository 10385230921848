"use client";

import { useDimension } from "@/hooks/useDimensions";
import { useEffect } from "react";


const CSSVariableSetter = () => {
  const [_, height] = useDimension();


  useEffect(() => {
    if (typeof document === "undefined") {
      return;
    }

    const heightToUse = height || window.innerHeight;

    // solution for 100vh on ios and android
    heightToUse && document.documentElement.style.setProperty("--window-height", `${heightToUse}px`);
  }, [height]);

  return <></>
}

export default CSSVariableSetter;
