"use client";

import { ReactNode } from "react";
import { ConfigProvider } from "antd";
import { IntercomProvider } from "react-use-intercom";
import locale from "antd/locale/en_GB";
import dynamic from "next/dynamic";
import "dayjs/locale/en-gb";

// components
const ConnectThirdPartyJs = dynamic(() => import("@/containers/ConnectThirdPartyJs"), { ssr: false });
import CSSVariableSetter from "@/containers/CSSVariableSetter";


function RootSettingsContext({ children }: { children: ReactNode }) {

  if (process.env.NEXT_PUBLIC_IS_OPS_DASHBOARD) {
    return (
      <ConfigProvider theme={{ hashed: false }} locale={locale}>
        <CSSVariableSetter />
        { children }
      </ConfigProvider>
    );
  }


  return (
    <ConfigProvider theme={{ hashed: false }} locale={locale}>
      <IntercomProvider autoBoot appId={process.env.NEXT_PUBLIC_INTERCOM_APP_ID as string}>
        <ConnectThirdPartyJs />
        <CSSVariableSetter />
        { children }
      </IntercomProvider>
    </ConfigProvider>
  );
}

export default RootSettingsContext;
