import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query-devtools/build/modern/index.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"style\":\"normal\",\"display\":\"swap\",\"variable\":\"--main-font-family\",\"adjustFontFallback\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"900\"],\"style\":\"normal\",\"display\":\"swap\",\"variable\":\"--secondary-font-family\",\"adjustFontFallback\":true,\"preload\":false}],\"variableName\":\"montserrat\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/local/target.css?{\"path\":\"src/assets/fonts/Archivo-Expanded/index.ts\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--landing-font-family\",\"src\":[{\"path\":\"./Archivo_Expanded-Medium.ttf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./Archivo_Expanded-Regular.ttf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"./Archivo_Expanded-Light.ttf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./Archivo_Expanded-ExtraLight.ttf\",\"weight\":\"400\",\"style\":\"normal\"}]}],\"variableName\":\"ArchivoExpanded\"}");
;
import(/* webpackMode: "eager" */ "/app/src/assets/styles/index.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/QueryClientProvider/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/contexts/RootSettingsContext.tsx");
