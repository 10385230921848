export function useSessionHistory() {

  const set = (pathname: string) => {
    if (typeof sessionStorage === "undefined") {
      return;
    }

    const sessionHistory = JSON.parse(sessionStorage.getItem("sessionHistory") || "[]");
    
    if (sessionHistory[sessionHistory.length - 1] !== pathname) {
      sessionHistory.push(pathname);
      sessionStorage.setItem("sessionHistory", JSON.stringify(sessionHistory.slice(-3)));
    }
  }

  const get = (): string[] => {
    if (typeof sessionStorage === "undefined") {
      return [];
    }

    return JSON.parse(sessionStorage.getItem("sessionHistory") || "[]");
  };

  return [get, set] as [() => string[], (pathname: string) => void];
}
